import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { newPatientMockedData, placeholdersTxt, tooltipsTxt } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer } from "../../components";

const NewPatientField = () => {
    const newPatientsData = newPatientMockedData;
    const selector = providerSelector;
    const selectedNewPatientsPrefference = useSelector(selector.selectedAcceptNewPatiences);
    const dispatch = useDispatch();

    const handleChange = (_event: any, newValue: String) => {
        const newPatience = newValue;
        dispatch(updateFilters({ selectedAcceptNewPatients: newPatience }))
    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    Accepting New Patients
                </div>
                <TooltipContainer
                    position="BottomCenter" content={tooltipsTxt.newPatient}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>

            </div>

            <div className="fieldgroup__field">
                <DropdownContainer
                    id={'NewPatientDropdown'}
                    className="fieldgroup__select"
                    dataSource={newPatientsData}
                    placeholder={placeholdersTxt.default}
                    onChange={handleChange}
                    value={selectedNewPatientsPrefference}
                    dataIsAnObject={false}
                    enabled={false}
                />
            </div>
        </div>
    )
}

export default NewPatientField;