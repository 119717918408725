import React, { FC } from 'react';
import Link from '@mui/material/Link';
import { get } from 'lodash';
import { ILinkContainerType } from '../models/LinkContainerType';

const LinkContainer: FC<ILinkContainerType> = ({ className, placeholder, urlAddress, spaSettings, underline = true, newTab }) => {
    const URL = get(window, "spaSettings." + urlAddress);
    return (
        <Link className={className} href={spaSettings ? URL : urlAddress} underline={underline ? "always" : "none"} target={newTab ? "_blank" : ""} rel={"noreferrer"}>{placeholder}</Link>
    )
}

export default LinkContainer;