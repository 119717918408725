import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { IProviderSearch, ISpecialtiesResponse } from './models/ProviderSearch';
import { mapProviderCategories } from './utils';
import { formatMedicalGroups, formatMedicalPlans, sanitizeParameter } from "../../ProviderSearch.CoreLibraries/components/utils";
import {
    ProviderSearchActions,
    searchProviderSuccess,
    searchProviderError,
    quickSearchSuccess,
    quickSearchError,
    getSpecialtiesSuccess,
    getSpecialtiesError,
    postEmailSuccess,
    postEmailError,
    getLanguagesSuccess,
    getLanguagesError,
    getProviderServicesSuccess,
    getProviderServicesError,
    postIncorrectInformationSuccess,
    postIncorrectInformationError,
    getPlanYearSwitchSuccess,
    getPlanYearSwitchError,
    askChatbotResponse
} from '../actions';

const initialState: IProviderSearch = {
    guidedSearch: {
        providers: null,
        facilityType: null,
        specialization: null,
        selectedCoverageYear: null,
        location: '',
        selectedDoctorType: null,
        showResults: 0,
        selectedPlan: null,
        selectedMedicalGroup: null,
        searchTerm: '',
        sortby: 'qualityrating,distance',
        withinmiles: 30,
        pageIndex: 1,
        totalRecords: 0,
    },
    quickSearch: {
        providers: null,
        location: '',
        searchTerm: '',
        showResults: 0,
        sortby: 'qualityrating,distance',
        withinmiles: 30,
        pageIndex: 1,
        totalRecords: 0,
    },
    selectedProviderType: 1,
    providerCategory: [1, 2, 7],
    specialtiesData: null,
    facilitiesData: ['All Facilities', 'Hospital', 'Urgent Care Center', 'Skilled Nursing Facility (SNF)'],
    coverageYearData: null,
    providerTypeData: null,
    serviceTypeData: null,
    languagesData: null,
    loading: false,
    loadingData: false,
    error: null,
    sortingData: false,
    updatingRadio: false,
    planData: null,
    medicalGroupsData: null,
    selectedVisitType: null,
    selectedLanguage: null,
    selectedGender: null,
    selectedAcceptNewPatients: null,
    selectedProviderAffiliation: null,
    selectedServiceType: null,
    selectedNPI: null,
    selectedProviderId: null,
    backToHome: false,
    selectedUSAState: null,
    successMessage: null,
    medicalGroupAffiliation: null,
    medicalGroupCount: null,
    emailSearch: false,
    loadingLanguages: false,
    tabIndex: 0,
    witResponse: null,
    isAllAccordionsExpanded: null,
    isSecondaryAccordionsExpanded: null,
    locationAccordionsExpanded: false,
}

const initialSearch: Partial<IProviderSearch> = {
    // cambiarlos manualmente en el guidedSearch
    // sortby: 'distance,name,lastname',
    // withinmiles: 30,
    // pageIndex: 1,
    // clearing second set of filters
    selectedVisitType: null,
    selectedLanguage: null,
    selectedGender: null,
    selectedAcceptNewPatients: null,
    selectedProviderAffiliation: null,
    selectedServiceType: null,
    // backToHome: false,
    emailSearch: false,
}

export const providerSearch = (state = initialState, action: any): IProviderSearch => {
    switch (action.type) {
        case ProviderSearchActions.CLEAR_STATE:
            return {
                ...state,
                ...initialSearch,
            }
        case ProviderSearchActions.SELECT_PROVIDER_TYPE:
            return {
                ...initialState,
                quickSearch: {
                    ...state.quickSearch
                },
                coverageYearData: state.coverageYearData,
                selectedProviderType: action.payload,
                providerCategory: mapProviderCategories[action.payload],
            }
        case ProviderSearchActions.TAB_INDEX:
            return {
                ...state,
                tabIndex: action.payload,
                backToHome: true,
            }
        case ProviderSearchActions.GET_SPECIALTIES:
            return {
                ...state,
                loading: true
            }
        case ProviderSearchActions.GET_SPECIALTIES_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        case ProviderSearchActions.GET_SPECIALTIES_ERROR:
            return {
                ...state,
                error: action.payload,
                specialtiesData: null,
                loading: false
            }
        case ProviderSearchActions.UPDATE_SEARCH:
            return {
                ...state,
                ...initialSearch,
                guidedSearch: {
                    ...state.guidedSearch,
                    ...action.payload.guidedSearch,
                    sortby: 'qualityrating,distance',
                    withinmiles: 30,
                    pageIndex: 1,
                },
                ...action.payload.root,
                loading: false
            }
        case ProviderSearchActions.UPDATE_QUICK_SEARCH:
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    ...action.payload.quickSearch,
                    withinmiles: 30,
                    pageIndex: 1,
                },
                ...action.payload.root,
            }
        case ProviderSearchActions.UPDATE_FILTERS: {
            let extraValues;
            if (!action.payload.emailSearch) {
                extraValues = {
                    ...state.guidedSearch,
                    ...action.payload,
                    sortby: 'qualityrating,distance',
                    withinmiles: 30,
                    pageIndex: 1,
                    showResults: 2,
                }
            } else {
                extraValues = action.payload.guidedSearch ?
                    { ...action.payload.guidedSearch } :
                    { ...state.guidedSearch }
            }
            return {
                ...state,
                ...action.payload,
                guidedSearch: {
                    ...extraValues,
                },
                backToHome: false,
                loading: true,
            }
        }
        case ProviderSearchActions.GET_PLAN_DATA:
            return {
                ...state,
                guidedSearch: {
                    ...state.guidedSearch,
                    showResults: state.emailSearch ? 2 : 0,
                    selectedPlan: state.emailSearch ? state.guidedSearch.selectedPlan : null,
                    selectedMedicalGroup: state.emailSearch ? state.guidedSearch.selectedMedicalGroup : null,
                },
                planData: null,
                medicalGroupsData: null,
                loadingData: true,
            }
        case ProviderSearchActions.REFRESH_PLAN_NETWORK:
            return {
                ...state,
                guidedSearch: {
                    ...state.guidedSearch,
                    ...action.payload.guidedSearch,
                },
                ...action.payload.root,
                loadingData: true,
                showResults: 0
            }
        case ProviderSearchActions.SEARCH_PROVIDERS:
            return {
                ...state,
                guidedSearch: {
                    ...state.guidedSearch,
                    showResults: action.payload,
                    providers: null,
                },
                loading: true
            }
        case ProviderSearchActions.SEARCH_PROVIDERS_SUCCESS: {
            let payloadResponse = {
                guidedSearch: {
                    ...action.payload.guidedSearch
                }
            }
            return {
                ...state,
                ...payloadResponse,
                ...action.payload.root,
                loading: false,
                loadingData: false,
                emailSearch: false,
                updatingRadio: false,
                sortingData: false
            }
        }
        case ProviderSearchActions.SEARCH_PROVIDERS_ERROR: {
            let payloadResponseError = {
                guidedSearch: {
                    ...state.guidedSearch,
                    providers: []
                }
            }
            return {
                ...state,
                ...payloadResponseError,
                planData: [],
                loading: false,
                loadingData: false,
                error: action.payload,
                emailSearch: false,
                updatingRadio: false,
                sortingData: false
            }
        }
        case ProviderSearchActions.QUICK_SEARCH:
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    showResults: action.payload,
                    providers: null,
                    sortby: 'qualityrating,distance',
                },
                loading: true
            }
        case ProviderSearchActions.QUICK_SEARCH_SUCCESS: {
            const quickResponse = {
                quickSearch: {
                    ...action.payload.quickSearch
                }
            }
            return {
                ...state,
                ...quickResponse,
                ...action.payload.root,
                loading: false,
                loadingData: false,
                emailSearch: false,
                updatingRadio: false,
                sortingData: false
            }
        }
        case ProviderSearchActions.QUICK_SEARCH_ERROR: {
            const quickResponseError = {
                quickSearch: {
                    ...action.payload.quickSearch
                }
            }
            return {
                ...state,
                ...quickResponseError,
                planData: [],
                loading: false,
                loadingData: false,
                error: action.payload,
                emailSearch: false,
                updatingRadio: false,
                sortingData: false
            }
        }
        case ProviderSearchActions.POST_EMAIL:
            return {
                ...state,
                loading: true
            }
        case ProviderSearchActions.POST_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            }
        case ProviderSearchActions.POST_EMAIL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case ProviderSearchActions.POST_INCORRECT_INFO:
            return {
                ...state,
                loading: true
            }
        case ProviderSearchActions.POST_INCORRECT_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload
            }
        case ProviderSearchActions.POST_INCORRECT_INFO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ProviderSearchActions.GET_PROVIDER_DETAILS:
            return {
                ...state,
                selectedNPI: action.payload,
                providerCategory: [],
                loading: true
            }
        case ProviderSearchActions.GET_PROVIDER_DETAILS_BY_PROVIDERID:
            return {
                ...state,
                selectedProviderId: action.payload,
                providerCategory: [],
                loading: true
            }
        case ProviderSearchActions.CHANGE_INDEX_PAGE:
            return {
                ...state,
                loading: true,
                [action.searchType]: {
                    ...state[action.searchType],
                    pageIndex: action.payload,
                },
            }
        case ProviderSearchActions.RESET_INDEX_PAGE:
            return {
                ...state,
                loading: true,
                [action.searchType]: {
                    ...state[action.searchType],
                    pageIndex: 1
                },
            }
        case ProviderSearchActions.SORT_BY:
            return {
                ...state,
                [action.searchType]: {
                    ...state[action.searchType],
                    sortby: action.payload,
                },
                sortingData: true
            }
        case ProviderSearchActions.CHANGE_RADIO:
            return {
                ...state,
                [action.searchType]: {
                    ...state[action.searchType],
                    withinmiles: action.payload,
                },
                updatingRadio: true,
            }
        case ProviderSearchActions.CLEAR_FILTERS:
            return {
                ...state,
                ...action.payload
            }
        case ProviderSearchActions.GET_LANGUAGES:
            return {
                ...state,
                loadingLanguages: true
            }
        case ProviderSearchActions.GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languagesData: action.payload,
                loadingLanguages: false
            }
        case ProviderSearchActions.GET_LANGUAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                languagesData: null,
                loadingLanguages: false
            }
        case ProviderSearchActions.SET_COMMING_BACK:
            return {
                ...state,
                backToHome: true,
            }
        case ProviderSearchActions.GET_PROVIDER_SERVICES:
            return {
                ...state,
                loading: true
            }
        case ProviderSearchActions.GET_PROVIDER_SERVICES_SUCCESS:
            return {
                ...state,
                serviceTypeData: action.payload,
                loading: false
            }
        case ProviderSearchActions.GET_PROVIDER_SERVICES_ERROR:
            return {
                ...state,
                error: action.payload,
                serviceTypeData: null,
                loading: false
            }
        case ProviderSearchActions.CLEAR_MESSAGES:
            return {
                ...state,
                error: null,
                successMessage: null,
            }
        case ProviderSearchActions.GET_PLANYEARSWITCH:
            return {
                ...state,
                loading: true
            }
        case ProviderSearchActions.GET_PLANYEARSWITCH_SUCCESS:
            return {
                ...state,
                loading: false,
                coverageYearData: action.payload
            }
        case ProviderSearchActions.GET_PLANYEARSWITCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ProviderSearchActions.ASK_CHATBOT:
            return {
                ...state,
                loading: true,
            }
        case ProviderSearchActions.ASK_CHATBOT_RESPONSE:
            return {
                ...state,
                loading: false,
                witResponse: action.payload
            }
        case ProviderSearchActions.EXPAND_COLLAPSE_ALL_ACCORDIONS:
            return {
                ...state,
                isAllAccordionsExpanded: action.payload
            }
        case ProviderSearchActions.EXPAND_COLLAPSE_SECONDARY_ACCORDIONS:
            return {
                ...state,
                isSecondaryAccordionsExpanded: action.payload
            }
        case ProviderSearchActions.EXPAND_ALL_LOCATION_ACCORDIONS:
            return {
                ...state,
                locationAccordionsExpanded: action.payload
            }
        default:
            return { ...state };
    }
}

export const searchProviderEpic = (action$: any, state$: any, { getJSON }) => action$.pipe(
    ofType(
        ProviderSearchActions.SEARCH_PROVIDERS,
        ProviderSearchActions.QUICK_SEARCH,
        ProviderSearchActions.GET_PLAN_DATA,
        ProviderSearchActions.GET_PROVIDER_DETAILS,
        ProviderSearchActions.GET_PROVIDER_DETAILS_BY_PROVIDERID,
        ProviderSearchActions.SORT_BY,
        ProviderSearchActions.CHANGE_RADIO,
        ProviderSearchActions.CLEAR_FILTERS,
        ProviderSearchActions.CHANGE_INDEX_PAGE,
        ProviderSearchActions.REFRESH_PLAN_NETWORK,
        ProviderSearchActions.UPDATE_FILTERS
    ),
    withLatestFrom(state$),
    mergeMap(() => {
        const providerSelection = state$.value.provider;

        const searchType = providerSelection.tabIndex === 0 ? 'guidedSearch' : 'quickSearch';

        const guidedSearch = providerSelection.guidedSearch;
        const quickSearch = providerSelection.quickSearch;
        let url: string;

        if (searchType === 'guidedSearch') {
            let planValue = guidedSearch.selectedPlan || '';
            if (planValue) {
                planValue = planValue.split(' ');
                planValue = planValue[planValue.length - 1];
            }
            const validateSpecialization = guidedSearch.specialization === 'All Other Specialty Services' ? '' : guidedSearch.specialization;
            const specialization = sanitizeParameter(validateSpecialization || '');

            url = `api/providers?type=${providerSelection.providerCategory}&location=${encodeURIComponent(guidedSearch.location)}&searchterm=${encodeURIComponent(guidedSearch.searchTerm)}&visittype=${providerSelection.selectedVisitType || ''}&npi=${providerSelection.selectedNPI || ''}&providerid=${providerSelection.selectedProviderId || ''}&sortby=${guidedSearch.sortby}&ipa=${providerSelection.selectedProviderAffiliation || ''}&medgrpnetwork=${guidedSearch.selectedMedicalGroup || ''}&withinmiles=${guidedSearch.withinmiles}&gender=${providerSelection.selectedGender || ''}&specialization=${specialization}&language=${providerSelection.selectedLanguage || ''}&acceptingpatients=${providerSelection.selectedAcceptNewPatients || ''}&pbpid=${planValue}&hospitalService=${providerSelection.selectedServiceType || ''}&planYear=${guidedSearch.selectedCoverageYear || ''}&pageIndex=${guidedSearch.pageIndex}`;
        } else {
            url = `api/providers?type=&location=${encodeURIComponent(quickSearch.location)}&searchterm=${encodeURIComponent(quickSearch.searchTerm)}&visittype=&npi=&sortby=${quickSearch.sortby}&ipa=&medgrpnetwork=&withinmiles=${quickSearch.withinmiles}&gender=&specialization=&language=&acceptingpatients=&pbpid=&hospitalService=&planYear=${Object.values(providerSelection.coverageYearData).join(",") || ''}&pageIndex=${quickSearch.pageIndex}`;
        }

        return getJSON(url).pipe(
            map((response: any) => {
                let newResponse;

                if (searchType === 'guidedSearch') {
                    const medicalPlans = formatMedicalPlans(response.distinctValues?.distinctPBPs || []);
                    const medicalGroupsAffiliation = formatMedicalGroups(response.distinctValues?.distinctIPAs || []);

                    newResponse = {
                        root: {
                            planData: !guidedSearch.selectedPlan || !providerSelection.planData ? medicalPlans : providerSelection.planData,
                            medicalGroupsData: (!guidedSearch.selectedMedicalGroup || !providerSelection.medicalGroupsData ? response.distinctValues?.distinctMedicalGroupNetwork : providerSelection.medicalGroupsData) ?? [],
                            medicalGroupAffiliation: !providerSelection.selectedProviderAffiliation || !providerSelection.medicalGroupAffiliation ? medicalGroupsAffiliation : providerSelection.medicalGroupAffiliation,
                        },
                        guidedSearch: {
                            ...providerSelection.guidedSearch,
                            providers: response.providers,
                            totalRecords: response.totalNPICount,
                            showResults: providerSelection.guidedSearch.showResults ? 2 : 0
                        }
                    }
                    return searchProviderSuccess(newResponse)
                } else {
                    newResponse = {
                        quickSearch: {
                            ...providerSelection.quickSearch,
                            totalRecords: response.totalNPICount,
                            providers: response.providers,
                            showResults: 2
                        }
                    }

                    return quickSearchSuccess(newResponse)
                }
            }),
            catchError((error: any) => {
                let errorMessage;
                if (error.status === 400 || error.status === 500) {
                    console.log(error)
                    errorMessage = 'It looks like the server encountered a problem, please try your search again soon';
                }
                else {
                    errorMessage = error.error;
                }
                if (searchType === 'guidedSearch') {
                    return of(searchProviderError(errorMessage));
                } else {
                    return of(quickSearchError(errorMessage))
                }
            })
        )
    })
);

export const searchSpecialtiesEpic = (action$: any, state$: any, { getJSON }) => action$.pipe(
    ofType(ProviderSearchActions.GET_SPECIALTIES),
    withLatestFrom(state$),
    mergeMap((action: any) => {
        const url = `api/speciality?providertype=${action[0].payload}`;
        return getJSON(url).pipe(
            map((response: any) => {
                const specialtiesData = response.map((specialty: ISpecialtiesResponse) => {
                    return specialty.description
                });

                if (action[0].payload === 2) {
                    specialtiesData.unshift('All Doctors', 'Primary Care Provider (PCP)', 'Outpatient Mental Health');
                }

                if (action[0].payload === 6) {
                    specialtiesData.unshift('All Other Specialty Services');
                }

                return getSpecialtiesSuccess({ specialtiesData });
            }),
            catchError((error: any) => of(getSpecialtiesError(error.response.title)))
        )
    })
);

export const getLanguagesEpic = (action$: any, state$: any, { getJSON }) => action$.pipe(
    ofType(ProviderSearchActions.GET_LANGUAGES),
    withLatestFrom(state$),
    mergeMap(() => {
        const url = 'api/language';
        return getJSON(url).pipe(
            map((response: string[]) => {
                return getLanguagesSuccess(response);
            }),
            catchError((error: any) => of(getLanguagesError(error.response.title)))
        )
    })
);

export const getProviderServicesEpic = (action$: any, state$: any, { getJSON }) => action$.pipe(
    ofType(ProviderSearchActions.GET_PROVIDER_SERVICES),
    withLatestFrom(state$),
    mergeMap(() => {
        const url = 'api/providerservice';
        return getJSON(url).pipe(
            map((response: any) => {
                const services = [...response.map((service: { service: string }) => service.service)].sort();
                return getProviderServicesSuccess(services);
            }),
            catchError((error: any) => of(getProviderServicesError(error.response.title)))
        )
    })
);

export const postEmailEpic = (action$: any, state$: any, { postINFO }) => action$.pipe(
    ofType(ProviderSearchActions.POST_EMAIL),
    withLatestFrom(state$),
    mergeMap((action: any) => {
        const url = action[0].payload.urlAddress;
        return postINFO(url, action[0].payload.emailDetails, { 'Content-Type': 'application/json' }).pipe(
            map(() => postEmailSuccess('Your email has been sent successfully!')),
            catchError((error: any) => {
                let errorMessage = error.response?.title;
                if (error.status === 500) {
                    errorMessage = "One or more fields are invalid. Please verify the information."
                }
                return of(postEmailError(errorMessage))
            })
        )
    })
);

export const postIncorrectInformationEpic = (action$: any, state$: any, { postINFO }) => action$.pipe(
    ofType(ProviderSearchActions.POST_INCORRECT_INFO),
    withLatestFrom(state$),
    mergeMap((action: any) => {
        const url = 'api/reportIncorrect';
        return postINFO(url, action[0].payload, { 'Content-Type': 'application/json' }).pipe(
            map((_response: any) => postIncorrectInformationSuccess("Your comments have been sent.")),
            catchError((error: any) => {
                let errorMessage = error.response?.title;
                if (error.status === 500) {
                    errorMessage = "Something went wrong."
                }
                return of(postIncorrectInformationError(errorMessage))
            })
        )
    })
);

export const getPlanYearSwitchEpic = (action$: any, state$: any, { getJSON }) => action$.pipe(
    ofType(ProviderSearchActions.GET_PLANYEARSWITCH),
    withLatestFrom(state$),
    mergeMap(() => {
        const url = 'api/PlanYearSwitch';
        return getJSON(url).pipe(
            map((response: any) => {
                let coverageData: number[];
                const tempDate = new Date(response['switchYearPlan']);
                const todayDate = new Date()
                if (todayDate < tempDate) {
                    coverageData = [todayDate.getFullYear(), todayDate.getFullYear() + 1];
                } else {
                    coverageData = [todayDate.getFullYear()];
                }
                return getPlanYearSwitchSuccess(coverageData)
            }),
            catchError((error: any) => {
                return of(getPlanYearSwitchError(error.response.title))
            })
        )
    })
)

export const getWitResponseEpic = (action$: any, state$: any, { postINFO }) => action$.pipe(
    ofType(ProviderSearchActions.ASK_CHATBOT),
    withLatestFrom(state$),
    mergeMap((action: any) => {
        const url = 'api/Wit';
        return postINFO(url, action[0].payload, { 'Content-Type': 'application/json' }).pipe(
            map((response: any) => {
                return askChatbotResponse(response.response)
            }),
            catchError((error: any) => {
                return of(askChatbotResponse(error.response))
            })
        )
    })
)
