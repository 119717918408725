import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setCommingBack } from "redux/actions";
import SendEmail from '../Email/SendEmail';
import { InternalLinkContainer, ButtonContainer } from "../index";
import { IBackToSearchResultsType } from '../models/BackToSearchResultsType';
import { printDocument } from '../utils';
import '../print.css';
import './BackToSearchResults.scss';

const BackToSearchResults: FC<IBackToSearchResultsType> = ({ displayIcons, placeholder, selectedProvider }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCommingBack())
    }, []);

    return (
        <div className="back-to-provider__div">
            <div className="back-to-provider__container">
                <InternalLinkContainer
                    className="back-to-provider__button"
                    placeholder={<><span style={{ fontSize: '32px' }}>&#8249;</span> {placeholder}</>}
                    urlAddress={'/'}
                    underline={false}
                />
                {displayIcons &&
                    <div className='back-to-provider__icons-data'>
                        <SendEmail
                            iconName="email"
                            selectedProvider={selectedProvider}
                        />
                        <ButtonContainer
                            className='send-email__container'
                            placeholder='Print'
                            onClick={printDocument}
                            icon="print"
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default BackToSearchResults;
