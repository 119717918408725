import React, { FC } from "react";
import { Link } from "react-router-dom";
import { getSpecialities } from "../../common/widgets";
import AcceptingConditions from "../AcceptingConditions/AcceptingConditions";
import { TooltipContainer } from "../../components";
import Icon from "../Icons/Icons";
import { IDoctorType } from "../models/DoctorType";
import { PcpBadge } from "../Tooltip/TooltipContent";
import "./Doctor.scss";

const Doctor: FC<IDoctorType> = ({ provider, showBadge }) => {
    const specialistSpecialties = getSpecialities(
        provider?.specialistSpecialties || []
    );
    const pcpSpecialities = getSpecialities(provider?.pcpSpecialities || []);
    const silverOrGold = (badgeValue: number | undefined) => {
        if (!badgeValue) return <></>;
        switch (badgeValue) {
            case 5:
                return <Icon icon={"gold"} />;
            case 4:
                return <Icon icon={"silver"} />;
            default:
                return <></>;
        }
    };
    const providerName = provider?.organizationName
        ? provider.organizationName
        : `${provider?.firstName} ${provider?.middleName} ${provider?.lastName} ${provider?.suffix !== "" ? "," : ""
        } ${provider?.suffix}`;

    return (
        <Link
            className={"doctorLink__Container"}
            to={`/${provider?.npi}`}
            key={provider?.npi}
        >
            <div className="information__innerContainer">
                <div className="provider__name">{providerName}</div>
                <div className="provider__information">
                    <p>
                        <strong>NPI: </strong>
                        {provider?.npi}
                    </p>
                    <p>
                        {provider?.isPCP && (
                            <span>
                                <strong>PCP: </strong>
                                {pcpSpecialities}
                                <br />
                            </span>
                        )}
                        {provider?.isSpecialist && (
                            <span>
                                <strong>Specialist: </strong>
                                {specialistSpecialties}
                                <br />
                            </span>
                        )}
                    </p>

                    <p>
                        <strong>Language: </strong>
                        {provider?.language}
                        {provider?.otherLanguages &&
                            provider?.otherLanguages.length > 0 &&
                            `, ${provider?.otherLanguages.join(", ")}`}
                    </p>

                    {provider?.gender !== "" && (
                        <p>
                            <strong>Gender: </strong>
                            {provider?.gender}
                        </p>
                    )}

                    {provider?.organizationName !== "" && (
                        <p>
                            {provider?.entityTypeCode == "2" ? (
                                <strong>Facility Type:&nbsp;</strong>
                            ) : (
                                <strong>Service Type:&nbsp;</strong>
                            )}
                            {provider?.classDescription !== "Hospital"
                                ? provider?.specialityDescription
                                : provider?.specialityDescription.split(",")[0]}
                        </p>
                    )}
                </div>
                {provider?.locations && (provider.isPCP || provider.isSpecialist) && (
                    <AcceptingConditions
                        locations={provider.locations}
                        newPatients={provider.acceptingNewPatients}
                    />
                )}
            </div>
            {showBadge && (
                <div className="provider-badge__container">
                    {provider && provider?.badge >= 4 && (
                        <TooltipContainer position="Center" content={<PcpBadge />}>
                            <span className="provider__badge">
                                {silverOrGold(provider?.badge)}
                            </span>
                        </TooltipContainer>
                    )}
                </div>
            )}
        </Link>
    );
};

export default Doctor;
