import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, updateQuickSearch } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { placeholdersTxt, tooltipsTxt, mobilePlaceholdersTxt } from "../../common/filters";
import { ProviderIcon, TooltipContainer } from "../../components";
import { isMobile } from "../../common/widgets";

const ProviderNameField = (props) => {
    const providerNameRef: any = useRef();
    const dispatch = useDispatch();
    const providerData = providerSelector;
    const searchTerm = useSelector(providerData.searchTerm);
    const selectedProviderType = useSelector(providerData.selectedProviderType);
    const tabIndex = useSelector(providerData.tabIndex);
    const [providerName, setProviderName] = useState(searchTerm);

    useEffect(() => {
        setProviderName(searchTerm)
    }, [searchTerm])

    useEffect(() => {
        if (providerName !== searchTerm) {
            if (tabIndex === 0) {
                dispatch(updateSearch({
                    guidedSearch: { searchTerm: providerName }
                }))
            } else {
                dispatch(updateQuickSearch({
                    quickSearch: { searchTerm: providerName }
                }))
            }
        }
    }, [providerName])

    const ph = isMobile() ? mobilePlaceholdersTxt : placeholdersTxt;

    const handleChange = (event: { target: { value: string; }; }) => {
        setProviderName(event.target?.value);
    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    {props.fieldText || 'Provider Name (optional)'}
                </div>
                <TooltipContainer
                    position="BottomCenter" content={tooltipsTxt.providerName}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>
            </div>

            <div className="fieldgroup__field">
                <input ref={providerNameRef} className="fieldgroup__field-input" placeholder={(tabIndex === 0 && selectedProviderType === 2) ? ph.providerNameFacility : ph.providerNameDoctor} onChange={handleChange} value={providerName || ''} />
            </div>
        </div>
    )
}

export default ProviderNameField;