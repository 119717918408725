import React, { useEffect, useState } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { providerSelector } from "redux/selectors/providerSelectors";
import { dentalData } from "../../common/filters";
import './DentalOptions.scss';

const DentalOptions = () => {
    const selector = providerSelector;
    const selectedUSAState = useSelector(selector.selectedUSAState);
    const selectedCorevageYear = useSelector(selector.selectedCoverageYear)
    const [showComprehensiveDisclaimer, setShowComprehensiveDisclaimer] = useState(false);
    const [showEnhancedDisclaimer, setShowEnhancedDisclaimer] = useState(false);
    const [urlRedirect, setUrlRedirect] = useState('')


    useEffect(() => {
        if (selectedCorevageYear && selectedUSAState) {
            console.log(process.env.REACT_APP_ENVIRONMENT)
            const temp = dentalData[selectedCorevageYear as unknown as keyof Object][selectedUSAState as keyof Object] as any;
            setUrlRedirect(temp['url'])
        }
    }, [selectedUSAState])

    const handleComprehensiveDisclaimer = () => {
        setShowComprehensiveDisclaimer(!showComprehensiveDisclaimer)
    }

    const handleEnhancedDisclaimer = () => {
        setShowEnhancedDisclaimer(!showEnhancedDisclaimer)
    }

    const dentalTextContent = () => {
        const temp = dentalData[selectedCorevageYear as unknown as keyof Object][selectedUSAState as keyof Object] as any;
        return temp['text'].map((item: any, indx: number) => {
            return (
                <p key={indx}>{item}</p>
            )
        })
    }

    const enhancedDentalText = () => {
        if (!selectedCorevageYear) {
            return '';
        }
        const temp = dentalData[selectedCorevageYear as unknown as keyof Object][selectedUSAState as keyof Object] as any;
        return temp['enhancedText'].map((item: any, indx: number) => {
            return (
                <p key={indx}>{item}</p>
            )
        })
    }

    const getProviderName = () => {
        const temp = dentalData[selectedCorevageYear as unknown as keyof Object][selectedUSAState as keyof Object] as any;
        return temp['providerName']
    }


    const getEnhancedProviderName = () => {
        if (!selectedCorevageYear) {
            return '';
        }
        const temp = dentalData[selectedCorevageYear as unknown as keyof Object][selectedUSAState as keyof Object] as any;
        return temp['enhancedProviderName'];
    }


    const getEnhancedUrl = () => {
        const temp = dentalData[selectedCorevageYear as unknown as keyof Object][selectedUSAState as keyof Object] as any;
        return temp['enhancedUrl']
    }

    const isCali23 = () => {
        return (selectedCorevageYear === 2023) && (selectedUSAState === "California");
    }

    const hasEnhancedOption = () => {
        return (isCali23() ||
        (selectedCorevageYear === 2024 && (selectedUSAState === 'Nevada' ||
        selectedUSAState === 'North Carolina')))
    }

    const headerOfAccordion = () => {

        if (hasEnhancedOption()) {
            return <div className="collapsable-section__header-title">Basic Supplemental Dental Coverage</div>
        }
        else {
            return <div className="collapsable-section__header-title">Comprehensive Dental Coverage (routine & comprehensive services) </div>
        }
    }

    const headerOfSecondAccordion = () => {

        if (selectedCorevageYear === 2024 && (selectedUSAState === 'Nevada' ||
        selectedUSAState === 'North Carolina')) {
            return <div className="collapsable-section__header-title">Discount Dental Plan</div>
        }
        else {
            return <div className="collapsable-section__header-title">Enhanced Dental Coverage (optional supplemental premium benefit) </div>
        }
    }

    return (
        <>
            {
                selectedCorevageYear && selectedUSAState && (
                    <>
                        <div className={`collapsable-section__container ${!showComprehensiveDisclaimer ? 'open' : ''}`}>
                            <div className="collapsable-section__header" onClick={handleComprehensiveDisclaimer}>
                                {
                                    headerOfAccordion()
                                }
                                <div className="collapsable-section__header-icon">
                                    <FontAwesomeIcon
                                        title="open section"
                                        icon={faAngleDown}
                                    />
                                </div>
                            </div>

                            {!showComprehensiveDisclaimer && (
                                <div className="collapsable-section__content">
                                    {
                                        dentalTextContent()
                                    }
                                </div>
                            )
                            }

                        </div>
                        {
                            (!isCali23()) && (
                                <Link
                                    className={`fieldgroup__submit`}
                                    href={urlRedirect}
                                    underline="none"
                                    target={'_blank'}
                                    role='link'
                                >
                                    Find a Dentist from {getProviderName()} Network
                                </Link>
                            )
                        }
                    </>
                )
            }
            {
                hasEnhancedOption() && (
                    <>
                        <hr />
                        <div className={`collapsable-section__container ${!showEnhancedDisclaimer ? 'open' : ''} with-margin`}>
                            <div className="collapsable-section__header" onClick={handleEnhancedDisclaimer}>
                                {
                                    headerOfSecondAccordion()
                                }
                                <div className="collapsable-section__header-icon">
                                    <FontAwesomeIcon
                                        title="open section"
                                        icon={faAngleDown}
                                    />
                                </div>
                            </div>
                            {
                                !showEnhancedDisclaimer && (
                                    <div className="collapsable-section__content">
                                        {
                                            enhancedDentalText()
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <Link
                            className={`fieldgroup__submit`}
                            href={selectedCorevageYear ? `${getEnhancedUrl()}` : '#'}
                            underline="none"
                            target={'_blank'}
                            role="link"
                        >
                            Find a Dentist from {getEnhancedProviderName()} Network
                        </Link>
                    </>
                )
            }
        </>
    )
}

export default DentalOptions;