import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Help from './components/Help';
import Home from './components/Home';
import ProviderDetailsContainer from './components/ProviderDetails';
import ScrollToTop from './components/ScrollTop';
import './App.scss';
import { initDataDog } from './datadog';

const App = () => {
    const [env, setEnv] = useState<string>('');

    useEffect(() => {
        if (env) {
            initDataDog(env);
        } else {
            fetch('api/environment')
                .then(response => response.json())
                .then(response => {
                    setEnv(response?.env)
                });
        }
    }, [env]);

    return (
        <>
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:npi" element={<ProviderDetailsContainer />} />
                    <Route path="/providerId/:providerId" element={<ProviderDetailsContainer />} />
                    <Route path="help" element={<Help />} />
                </Routes>
            </ScrollToTop>
        </>
    );
}

export default App;
