import React, { useState } from 'react'
import { Link, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearMessages, postIncorrectInformation } from 'redux/actions';
import { DialogContainer, ButtonContainer } from "../../components";
import { IProviderItemType } from '../models/ProviderDataType';
import { createPayload, sleep } from '../utils';
import './ReportIncorrectInformation.scss';

const ReportIncorrectInformation = (provider: IProviderItemType) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [reportMessage, setReportMessage] = useState("");
    const [sended, setSended] = useState(false);

    const handleModal = () => {
        if (showModal) {
            setReportMessage("");
            setSended(false);
            dispatch(clearMessages());
        }
        setShowModal(!showModal);
    }

    const handleChange = (event: { target: { value: any } }) => {
        setReportMessage(event.target.value);
    }

    const handleSubmit = () => {
        if (reportMessage) {
            setSended(!sended)
            sleep(2000).then(_response => {
                handleModal();
                const payload = createPayload(reportMessage, provider.provider?.firstName || '', provider.provider?.lastName || '', Number(provider.provider?.npi));
                dispatch(postIncorrectInformation(JSON.stringify(payload)));
            })

        }

    }

    const headerTemplate = () => {
        return (
            <div className='dialog__header'>
                <div className='dialog__header-title'>
                    Report incorrect information
                </div>
                <button
                    className='dialog__header-btn'
                    onClick={handleModal}
                > X </button>
            </div>
        )
    }

    const handleContent = () => {
        return (
            <div className='report-information__content'>
                <div className='fieldgroup__field'>
                    <TextField className="fieldgroup__field-input" id="reportMessage" name="reportMessage" value={reportMessage} label="" variant="standard" fullWidth multiline rows={4} color='secondary' onChange={handleChange} />
                </div>
            </div>

        )
    }

    const footerTemplate = () => {
        return (
            <>
                <div className="report-information__footer">
                    <ButtonContainer
                        className='dialog__container-btn cancel'
                        placeholder='Cancel'
                        onClick={handleModal}
                    />

                    <ButtonContainer
                        className='dialog__container-btn'
                        placeholder='Send'
                        onClick={handleSubmit}
                        disabled={Boolean(!reportMessage)}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <Link className={'hyperlink'} onClick={handleModal}>Report incorrect information</Link>
            <DialogContainer
                content={handleContent}
                header={headerTemplate}
                footer={footerTemplate}
                visible={showModal}
            />
        </>
    )
}

export default ReportIncorrectInformation;