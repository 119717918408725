import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, resetIndexPage } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt, tooltipsTxt } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer, Icon } from "../../components";
import { needResetIndexPage } from "../utils";

const CoverageYearField = () => {
    const selector = providerSelector;
    const coverageData = useSelector(selector.coverageYearData);
    const selectedCoverageYear = useSelector(selector.selectedCoverageYear);
    const indexPage = useSelector(selector.indexPage);
    const selectedUSAState = useSelector(selector.selectedUSAState);
    const dispatch = useDispatch();

    const handleChange = (_event: any, newValue: string) => {
        const selectedYear = newValue ? Number(newValue) : null;
        if (selectedUSAState) {
            dispatch(updateSearch({
                root: {
                    selectedUSAState: null
                }
            }));
        }
        dispatch(updateSearch({
            guidedSearch: {
                selectedCoverageYear: selectedYear
            }
        }));
        if (needResetIndexPage(indexPage)) {
            dispatch(resetIndexPage('guidedSearch'))
        }
    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    <span>Coverage Year</span>
                    <Icon icon="asterisk" className="fieldgroup__placeholder-asterisk" />
                </div>
                <TooltipContainer
                    position="BottomCenter" content={tooltipsTxt.coverage}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>
            </div>

            <div className="fieldgroup__field">
                <DropdownContainer
                    id={"CoverYearDropDown"}
                    className="fieldgroup__select"
                    dataSource={coverageData}
                    placeholder={placeholdersTxt.coverage}
                    onChange={handleChange}
                    value={selectedCoverageYear}
                    dataIsAnObject={false}
                    enabled={false}
                />
            </div>
        </div>
    )
}

export default CoverageYearField;