import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProviderDetails, getProviderDetailsByProviderId, updateFilters } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { BackToSearchResults, AlertContainer, Locations, PCPDetails } from "../ProviderSearch.CoreLibraries";
import GoTopButton from "../ProviderSearch.CoreLibraries/components/Button/GoTopButton";
import { IProviderDataType } from "../ProviderSearch.CoreLibraries/components/models/ProviderDataType";
import './ProviderDetails.scss';

const ProviderDetailsContainer = () => {
    const provider = useParams();
    const dispatch = useDispatch();
    const { npi, providerId } = provider;
    const selector = providerSelector;

    // this is going to help us to validate if we already have data or if user is loading a provider page
    const providersData = useSelector(selector.providers);
    const hasError = useSelector(selector.hasError);
    const hasMessage = useSelector(selector.hasMessage);
    const emailSearch = useSelector(selector.emailSearch);
    const [selectedProvider, setSelectedProvider] = useState(null);

    //I dont think so, that this need the spinner, but I left the comment bc the action change "loading"
    useEffect(() => {
        if (!providersData && npi) {
            dispatch(getProviderDetails(npi))
            dispatch(updateFilters({emailSearch: true}))
        }else if (!providersData && providerId) {
            dispatch(getProviderDetailsByProviderId(providerId))
            dispatch(updateFilters({emailSearch: true}))
        }

        if (providersData) {
            const providerDetails = providersData.find((provider:IProviderDataType) => {
                if (npi) {
                    return provider.npi === npi
                }else if (providerId) {
                    return provider.medicalGroups.find(medicalGroup=>{
                        return medicalGroup.providerID === providerId
                    })
                }
            });

            setSelectedProvider(providerDetails);
        }
    }, [npi, providersData]);


    return (
        <>
            <BackToSearchResults
                displayIcons={true}
                placeholder={`${emailSearch ? 'Back to Home' : 'Back to Search Results'}`}
                selectedProvider={selectedProvider} />
            <PCPDetails provider={selectedProvider}/>
            <span className="PCP_details__locations_and_below">
                <Locations provider={selectedProvider}/>
                <GoTopButton
                    desktopCondition={true}
                    mobileCondition={true}
                />
            </span>

            {
                hasError && (
                    <AlertContainer severity='error' message={hasError} />
                )
            }

            {
                hasMessage && (
                    <AlertContainer severity='success' message={hasMessage} />
                )
            }
        </>
    )
}

export default ProviderDetailsContainer;