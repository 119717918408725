import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ITooltipType } from '../models/TooltipType';

const TooltipContainer: FC<ITooltipType> = ({ content, children, disableHover }) => {
    return (
        //here for some reason the react.fragment doesnt work, pls dont change it (I'm talking about the children prop)
        <Tooltip title={<>{content}</>} placement="top-start" arrow enterTouchDelay={0} disableHoverListener={disableHover}>
            <div className='tooltip__container'>
                {children}
            </div>
        </Tooltip>
    )
}

export default TooltipContainer