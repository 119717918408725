import React from "react";
import { InternalLinkContainer } from '../../index';
import Banner from '../Banner/Banner';
import './HeaderFindAProvider.scss';

const HeaderFindAProvider = () => {
    return (
        <>
            <div className='findAProviderHeader'>
                <Banner />
                <InternalLinkContainer
                    className='findAProviderHeader__help'
                    placeholder='Provider Search Help'
                    urlAddress={'help'}
                />
            </div>
        </>
    )
}

export default HeaderFindAProvider;
