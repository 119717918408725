import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ILinkContainerType } from '../models/LinkContainerType';

const InternalLinkContainer: FC<ILinkContainerType> = ({className, placeholder, urlAddress}) => {
    return (
        <Link className={className} to={urlAddress} role="link">{placeholder}</Link>
    )
}

export default InternalLinkContainer;