import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, getPlanData } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { placeholdersTxt, tooltipsTxt, dataCategoTypeForFacilities } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer, Icon } from "../../components";

const FacilitiesTypeField = () => {
    const providerData = providerSelector;
    const facilitiesTypeData = useSelector(providerData.facilitiesData);
    const selectedFacilityType = useSelector(providerData.selectedFacilityType);
    const selectedLocation = useSelector(providerData.selectedLocation);
    const selectedCoverageYear = useSelector(providerData.selectedCoverageYear);
    const dispatch = useDispatch();

    const handleChange = (_event: any, newValue: String) => {
        let facilityType: String | null = newValue;
        let providerCategory = [dataCategoTypeForFacilities.get(newValue)];

        if (newValue === 'All Facilities') {
            providerCategory = [3, 4, 5];
        }

        dispatch(updateSearch({
            guidedSearch: {
                facilityType,
            },
            root: {
                providerCategory
            }
        }));

        if(selectedLocation && selectedCoverageYear) {
            dispatch(getPlanData());
        }
    }
    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    <span>Facility Type</span>
                    <Icon icon="asterisk" className="fieldgroup__placeholder-asterisk" />
                </div>
                <TooltipContainer
                    position="BottomCenter" content={tooltipsTxt.facilities}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>
            </div>

            <div className="fieldgroup__field">
                <DropdownContainer
                    id={'FacilitiesDropdown'}
                    className="fieldgroup__select"
                    dataSource={facilitiesTypeData}
                    placeholder={placeholdersTxt.facilities}
                    onChange={handleChange}
                    value={selectedFacilityType || null}
                    dataIsAnObject={false}
                    enabled={false}
                />
            </div>
        </div>
    )
}

export default FacilitiesTypeField;