import React, { FC } from 'react';
import {AiFillCheckCircle, AiFillExclamationCircle,} from 'react-icons/ai';
import { hasPbpWithAvaHmo, ParseAcceptingAVAHmo } from '../avaHmo';
import { IAcceptingConditions } from '../models/AcceptionConditionsType';

const AcceptingConditions: FC<IAcceptingConditions> = ({locations, newPatients}) => {
    return (
        <div className='provider__labels'>
            <p className='icon__container'>
                {
                    newPatients ? (
                        <>
                            <AiFillCheckCircle className='check__icon'/> {locations && locations[0].medicalGroupsParticipated[0].panelDescription}
                        </>
                    ) : (
                        <>
                            <AiFillExclamationCircle className='exclamation__icon'/> {locations && locations[0].medicalGroupsParticipated[0].panelDescription}
                        </>
                    )
                }
            </p>
            <p className='icon__container'>
                {hasPbpWithAvaHmo(locations) && (
                    <ParseAcceptingAVAHmo locations={locations} />
                )}
            </p>
        </div>
    )
}

export default AcceptingConditions;