import React, { FC, useEffect, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, updateQuickSearch, resetIndexPage } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt, mobilePlaceholdersTxt } from "../../common/filters";
import { LocationFieldType } from "../models/LocationFieldType";
import { needResetIndexPage } from "../utils";
import { isMobile } from "../../common/widgets";
import { Icon } from "../../components";

const LocationField: FC<LocationFieldType> = ({ isRequired }) => {
    const providerData = providerSelector;
    const selectedLocation = useSelector(providerData.selectedLocation);
    const indexPage = useSelector(providerData.indexPage);
    const tabIndex = useSelector(providerData.tabIndex);
    const dispatch = useDispatch();
    const locationRef: any = useRef();
    const [showError, setShowError] = useState(false);

    const handlePlaceSelected = (event: any) => {
        const location = event.formatted_address;
        if (location !== selectedLocation) {
            if (tabIndex === 0) {
                dispatch(updateSearch({
                    guidedSearch: { location }
                }))
            } else {
                dispatch(updateQuickSearch({
                    quickSearch: { location }
                }))
            }
            if (needResetIndexPage(indexPage)) {
                let searchType;
                if (tabIndex === 0) {
                    searchType = 'guidedSearch';
                } else {
                    searchType = 'quickSearch';
                }
                dispatch(resetIndexPage(searchType));
            }
            setShowError(false);
        }
    }

    const handleBlur = (event: any) => {
        if (!event.target.value) {
            setShowError(false);
            if (tabIndex === 0) {
                dispatch(updateSearch({
                    guidedSearch: { location: "" }
                }))
            } else {
                dispatch(updateQuickSearch({
                    quickSearch: { location: "" }
                }))
            }
            return;
        }
        if (event.target.value !== selectedLocation) {
            handlePlaceSelected({ formatted_address: event.target.value })
        }
    }

    const ph = isMobile() ? mobilePlaceholdersTxt : placeholdersTxt;

    useEffect(() => {
        locationRef.current.value = selectedLocation;
        const myInput = document.getElementById(
            "location-text-box"
        ) as HTMLInputElement;
        myInput?.setAttribute("autocomplete", "off");
        myInput?.setAttribute("type", "text");
    }, [selectedLocation]);

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    {isRequired ? (
                        <>
                            <span>Location</span>
                            <Icon icon="asterisk" className="fieldgroup__placeholder-asterisk" />
                        </>
                    ) : "Location"}
                </div>
            </div>

            <div className={`fieldgroup__field ${showError ? "error" : ""}`}>
                <Autocomplete
                    ref={locationRef}
                    className="fieldgroup__field-input"
                    id="location-text-box"
                    onPlaceSelected={handlePlaceSelected}
                    placeholder={ph.location}
                    aria-label="Search by zip code or location"
                    options={{
                        types: ['postal_code', 'locality', 'administrative_area_level_2', 'neighborhood'],
                        componentRestrictions: { country: "us" }
                    }}
                    defaultValue={selectedLocation}
                    apiKey={'AIzaSyCZUXjld2wi_EmRj5H4Y6idtjx5lkvrjn4'}
                    onBlur={handleBlur}
                />
            </div>

            {showError && <p className="error-message">Please select a value</p>}
        </div>
    );
};

export default LocationField;
