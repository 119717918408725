import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt, tooltipsTxt } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer } from "../../components";

const MedicalAffiliationField = () => {
    const selector = providerSelector;
    const medicalData = useSelector(selector.affiliationData);
    const selectedAffiliation = useSelector(selector.selectedProviderAffiliation);
    const dispatch = useDispatch();
    const [selectedGroup, setSelectedGroup] = useState(null); //for local usage

    useEffect(() => {
        if (selectedAffiliation && medicalData) {
            const temp = medicalData?.find((element: any) => element['value'] === selectedAffiliation)
            setSelectedGroup(temp)
        } else {
            setSelectedGroup(null)
        }
    }, [selectedAffiliation, medicalData])

    const handleChange = (_event: any, newValue: String | any, reason: any) => {
        if (reason === 'clear') {
            dispatch(updateFilters({ selectedProviderAffiliation: null }));
            setSelectedGroup(null)
            return;
        }
        const affiliation = newValue;
        dispatch(updateFilters({ selectedProviderAffiliation: affiliation['value'] }));
        setSelectedGroup(affiliation)
    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    Provider’s Medical Group Affiliation
                </div>
                <TooltipContainer
                    position="BottomCenter" content={tooltipsTxt.medicalGroupAffiliation}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>

            </div>

            <div className="fieldgroup__field">
                <DropdownContainer
                    id={'MedicalAffiliationDropdown'}
                    className="fieldgroup__select"
                    dataSource={medicalData}
                    placeholder={placeholdersTxt.medicalGroupAffiliation}
                    value={selectedGroup}
                    onChange={handleChange}
                    dataIsAnObject={true}
                    enabled={!medicalData}
                    showClearButton={false}
                />
            </div>
        </div>
    )
}

export default MedicalAffiliationField;