import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt, visitTypeData } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer } from "../../components";

const VisitTypeField = () => {
    const selector = providerSelector;
    const selectedVisitType = useSelector(selector.selectedVisitType);
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState(null)

    useEffect(() => {
        if (selectedVisitType) {
            const temp = visitTypeData.find((element) => element['value'] === selectedVisitType) as any;
            setSelectedType(temp);
        } else {
            setSelectedType(null)
        }
    }, [selectedVisitType])

    const handleChange = (_event: any, newValue: String | any, reason: any) => {
        if (reason === 'clear') {
            dispatch(updateFilters({ selectedVisitType: null }))
            setSelectedType(null)
            return
        }

        dispatch(updateFilters({ selectedVisitType: newValue['value'] }))
        setSelectedType(newValue)
    }

    const handleContent = () => {
        return (
            <div id='tooltipContent'>
                <p>
                    <strong>In-Office: </strong> Providers who offer in-office visits.
                </p>
                <p>
                    <strong>Home Visits: </strong> Providers who offer visits to your home.
                </p>
                <p>
                    <strong>Virtual: </strong> Primary Care Providers (PCPs) who offer virtual visits that may serve as assigned PCPs for AVA (HMO) products.
                </p>
            </div>
        )
    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    Visit Type
                </div>
                <TooltipContainer
                    position="BottomCenter" content={handleContent()}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>

            </div>

            <div className="fieldgroup__field">
                <DropdownContainer
                    id={'VisitDropdown'}
                    className="fieldgroup__select"
                    dataSource={visitTypeData}
                    placeholder={placeholdersTxt.default}
                    value={selectedType}
                    onChange={handleChange}
                    dataIsAnObject={true}
                    enabled={false}
                />
            </div>
        </div>
    )
}

export default VisitTypeField;