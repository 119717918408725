import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, getSpecialties, getPlanData } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { placeholdersTxt, tooltipsTxt } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer, Spinner, Icon } from "../../components";

const SpecialtiesServicesField = () => {
    const providerData = providerSelector;
    const servicesData = useSelector(providerData.specialtiesData);
    const selectedSpecialtyService = useSelector(providerData.selectedSpecialization);
    const selectedLocation = useSelector(providerData.selectedLocation);
    const selectedCoverageYear = useSelector(providerData.selectedCoverageYear);
    const dispatch = useDispatch();

    const handleChange = (_event: any, newValue: String | any) => {
        dispatch(updateSearch({
            guidedSearch: { specialization: newValue }
        }));

        if(selectedLocation && selectedCoverageYear) {
            dispatch(getPlanData());
        }
    }

    useEffect(() => {
        if (!servicesData) {
            // we pass a number 6 as param because we want to get the same values as the current PS when searching by Other Specialty Services
            dispatch(getSpecialties(6));
        }
    }, [servicesData]);

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    <span>Specialty Services</span>
                    <Icon icon="asterisk" className="fieldgroup__placeholder-asterisk" />
                </div>
                <TooltipContainer
                    position="BottomCenter" content={tooltipsTxt.services}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>
            </div>

            <div className={!servicesData ? 'fieldgroup__field_loading' : `fieldgroup__field`}>
                <DropdownContainer
                    id={'SpecialitiesDropdown'}
                    className="fieldgroup__select"
                    dataSource={servicesData}
                    placeholder={placeholdersTxt.services}
                    onChange={handleChange}
                    value={selectedSpecialtyService || null}
                    enabled={!servicesData}
                />
                {
                    !servicesData && (
                        <Spinner size={40}/>
                    )
                }
            </div>
        </div>
    )
}

export default SpecialtiesServicesField;